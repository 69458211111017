import { useState, useEffect, useCallback } from 'react';
import { fetchFactory } from '../../api/apiFactory';
import { METHOD_GET } from '../../utils/constants';
const URL = process.env.REACT_APP_BACKEND_URL + `/release-info`;
const useViewModel = () => {
  const [showReleaseNotes, setShowReleaseNotes] = useState(false);
  const [releaseVersion, setReleaseVersion] = useState('');
  const [releaseNotes, setReleaseNotes] = useState([{}]);

  const fetchReleaseNotes = useCallback(async () => {
    const isNotDefaultReleaseNotes = releaseNotes.length !== 1 || Object.keys(releaseNotes[0]).length !== 0;
    if (releaseVersion !== '' && isNotDefaultReleaseNotes) {
      // If releaseVersion and releaseNotes are already set, do nothing
      return;
    }

    try {
      const response = await fetchFactory(URL)(METHOD_GET)();
      const data = await response.json();
      setReleaseVersion(data.latest_release.release_version);
      setReleaseNotes(data.latest_release.release_notes);
      localStorage.setItem('releaseVersion', data.latest_release.release_version);
      localStorage.setItem('releaseNotes', JSON.stringify(data.latest_release.release_notes));
      if (!data.latest_release.seen_before) {
        setShowReleaseNotes(true);
      } else {
        setShowReleaseNotes(false);
      }
    } catch (error) {
      console.warn('Unable to load release notes. Falling back to default', error);
    }
  }, [releaseVersion, releaseNotes]);

  useEffect(() => {
    fetchReleaseNotes();
  }, [fetchReleaseNotes]);

  const handleClose = useCallback(() => {
    setShowReleaseNotes(false);
  }, []);

  return { showReleaseNotes, handleClose, releaseVersion, releaseNotes };
};

export default useViewModel;
