export const transformConversations = (conversations: Conversation[]) => {
  if (conversations.length <= 0) {
    return [];
  }

  return [
    {
      title: 'Your conversations',
      conversations,
    },
  ];
};
