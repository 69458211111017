import React from 'react';
import { useConversations } from '../../contexts/ConversationsContext';
import ChatBubble from '../ChatBubble';

interface Props {
  history: Message[];
}

const defaultDownload: DownloadType = {
  download_name: '',
  download_token: '',
};
const ChatContainer: React.FC<Props> = ({ history }) => {
  const { conversationId } = useConversations();
  return (
    <>
      <div className="mt-2">
        {history.map((element, index) => (
          <ChatBubble
            key={`${element.role}-${element.content?.substring(0, 50)}-${index}`}
            role={element.role}
            content={element.content}
            index={index}
            download={element?.download || defaultDownload}
            conversationId={conversationId}
          />
        ))}
      </div>
    </>
  );
};

export default ChatContainer;
