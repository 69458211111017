import { useState, useCallback, useMemo, useEffect } from 'react';

export interface DropzoneOptions {
  onDrop?: (event: React.DragEvent) => void;
}

export interface DropzoneProps {
  onDragOver: (event: React.DragEvent) => void;
  onDragEnter: (event: React.DragEvent) => void;
  onDragLeave: (event: React.DragEvent) => void;
  onDrop: (event: React.DragEvent) => void;
}

export interface DropzoneState {
  isDragging: boolean;
  isHovering: boolean;
}

function useDropzone(options: DropzoneOptions = {}): readonly [DropzoneProps, DropzoneState] {
  const { onDrop: externalOnDrop } = options;

  const [isDragging, setIsDragging] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const onDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
  }, []);

  const onDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsHovering(true);
  }, []);

  const onDragLeave = useCallback((e: React.DragEvent) => {
    setIsHovering(false);
  }, []);

  const onDrop = useCallback(
    (e: React.DragEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setIsDragging(false);
      setIsHovering(false);
      if (externalOnDrop) {
        externalOnDrop(e);
      }
    },
    [externalOnDrop],
  );

  // Document event listeners
  useEffect(() => {
    const handleDragEnter = () => {
      setIsDragging(true);
    };
    const handleDragLeave = (e: DragEvent) => {
      // Disable dragging when the mouse leaves the window
      if (!e.relatedTarget) setIsDragging(false);
    };
    // Prevent file from being opened in the browser
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(false);
    };
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
    };
    // Stop dragging when the window loses focus
    const handleBlur = () => {
      setIsDragging(false);
    };

    document.addEventListener('dragenter', handleDragEnter);
    document.addEventListener('dragleave', handleDragLeave);
    document.addEventListener('drop', handleDrop);
    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('blur', handleBlur);

    return () => {
      document.removeEventListener('dragenter', handleDragEnter);
      document.removeEventListener('dragleave', handleDragLeave);
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('blur', handleBlur);
    };
  }, []);

  const props = useMemo(
    () => ({
      onDragOver,
      onDragEnter,
      onDragLeave,
      onDrop,
    }),
    [onDragOver, onDragEnter, onDragLeave, onDrop],
  );

  const state = useMemo(() => ({ isDragging, isHovering }), [isDragging, isHovering]);

  return [props, state] as const;
}

export default useDropzone;
