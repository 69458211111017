import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import companyLogo from '../../img/sartorius-logo-data.svg';
import UserInfo from '../UserInfo';
import './index.css';

const TopNavigation = () => {
  return (
    <Navbar bg="frames" variant="dark">
      <div className="container-fluid">
        <Navbar.Brand>
          <Link to="/">
            <div className="d-flex align-items-baseline">
              <div className="me-2">
                <img src={companyLogo} className="Company-logo" alt="logo" style={{ height: '1.3rem' }} />
              </div>
              <div className="navbar-text-container align-items-baseline">
                <div
                  className="srt-text"
                  style={{
                    lineHeight: '1.3rem',
                    fontSize: '1.3rem',
                    position: 'relative',
                    bottom: '-0.2em',
                    left: '0.2em',
                  }}
                >
                  <span>{`Chat AI`}</span>
                </div>
                <div className="srt-text">
                  <b>
                    <i>
                      <span
                        style={{ fontSize: '1.1rem', position: 'relative', bottom: '-0.2em' }}
                      >{`Powered by GPT-4o`}</span>
                    </i>
                  </b>
                </div>
              </div>
            </div>
          </Link>
        </Navbar.Brand>
        <UserInfo />
      </div>
    </Navbar>
  );
};
export default TopNavigation;
