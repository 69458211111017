import { ChangeEvent, useCallback, useState } from 'react';
import getChat from './getChatApi';
import { METHOD_POST } from '../../utils/constants';
import { fetchFactory } from '../../api/apiFactory';
import { useNavigate } from 'react-router-dom';
import useFileUpload from '../../hooks/useFileUpload';
import useChat from '../../hooks/useChat';
import { useConversations } from '../../contexts/ConversationsContext';

interface Props {}

const useViewModel = ({}: Props = {}) => {
  const { history, setHistory, conversationId, setConversationId } = useConversations();
  const [formData, setFormData] = useState('');
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(false);
  const stopGenerationUrl = `${process.env.REACT_APP_BACKEND_URL}/stop_streaming/` + conversationId;
  const navigate = useNavigate();
  const chat = useChat();

  const { handleFileUpload: uploadFile, loading: fileUploadLoading } = useFileUpload({
    onFileUploaded: data => {
      chat.append(data.messages ?? [], data.conversation_id);
    },
  });
  const handleFileUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target && e.target.files) {
        await uploadFile(e.target.files);
        e.target.value = '';
      }
    },
    [uploadFile],
  );

  const stopGeneration = async () => {
    let response;
    setLoading(false);
    response = await fetchFactory(stopGenerationUrl)(METHOD_POST)();
    if (!response.ok) {
      if (response.status === 504) {
        navigate('/504-error'); // Redirect to the 504 error page
      }
      throw response;
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setFormData(event.target.value);
  };

  const fetchChatMessage = () => {
    getChat({
      history,
      formData,
      setHistory,
      setFormData,
      setLoading,
      conversationId,
      setConversationId,
      setId,
    });
    setLoading(true);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    fetchChatMessage();
  };

  const handleKeyDown: React.KeyboardEventHandler<FormControlElement> = event => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      if (!loading && formData) {
        fetchChatMessage();
      }
    } else if (!event.shiftKey && event.key === 'ArrowUp') {
      if (!loading && !formData) {
        event.preventDefault();
        const historyReversed = [...history].reverse();
        const lastUserMessage = historyReversed.find(el => el.role === 'user');
        if (lastUserMessage) {
          setFormData(lastUserMessage.content);
        }
      }
    }
  };

  return {
    history,
    setFormData,
    setLoading,
    handleInputChange,
    handleSubmit,
    handleKeyDown,
    formData,
    loading: loading || fileUploadLoading,
    handleFileUpload,
    stopGeneration,
    id,
  };
};

export default useViewModel;
