import BaseModalComponent from './Component';

interface Props {
  children: any;
  title: string;
  testId: string;
  footer: any;
  show: boolean;
  close: any;
}

const BaseModal = ({ children, title, testId, footer, show, close }: Props) => {
  return <BaseModalComponent {...{ children, footer, testId, title, show, close }} />;
};

export default BaseModal;
