import React, { useContext } from 'react';
import BaseModal from '../BaseModal/index';
import TermsOfServiceCheckbox from './TermsOfServiceCheckbox';
import { TermsOfService } from './termsOfService';
import useViewModel from './viewModel';
import { ToSContext } from '../../contexts/ToSContext';

const TermsOfServiceModal: React.FC = () => {
  const { approveTerms } = useViewModel();

  const { show } = useContext(ToSContext);

  return (
    <BaseModal
      footer={<TermsOfServiceCheckbox callOnChecked={approveTerms} />}
      show={show}
      testId="TermsOfServiceModal"
      title="Chat AI Terms of Service"
      close=""
    >
      <TermsOfService />
    </BaseModal>
  );
};

export default TermsOfServiceModal;
