import { PublicClientApplication, InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { MsalProvider, useMsalAuthentication, AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { msalConfig } from './authconfig';

export const msalInstance = new PublicClientApplication(msalConfig);

export const AuthenticationProvider = ({ children }: { children: any }) => (
  <MsalProvider instance={msalInstance}>{children}</MsalProvider>
);

export const useAuthentication = () => useMsalAuthentication(InteractionType.Redirect);

export { AuthenticatedTemplate };

export const getAccessTokenWithoutHook = async (scopes: string[]) => {
  const request = { scopes };
  try {
    const accounts = msalInstance.getAllAccounts();
    msalInstance.setActiveAccount(accounts[0]);
    const tokenResponse = await msalInstance.acquireTokenSilent(request);
    return tokenResponse.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenRedirect(request);
    }
    console.error('unable to fetch token', error);
  }
};

export const useAccessTokenLazy = () => {
  const { instance, accounts } = useMsal();
  const account = accounts[0];

  const getAccessToken = async (scopes: string[]) => {
    if (account) {
      const authenticationResult = await instance.acquireTokenSilent({ scopes: scopes, account: account });
      return authenticationResult.accessToken;
    }
  };

  return { getAccessToken };
};
