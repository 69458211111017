import './index.css';
import DeleteDialog from '../../Dialog/Dialog';
import { useState } from 'react';
import { autofocusCursor } from '../../../utils/autofocusCursor';
import { toast } from '../../Toast';
import { getSharedId } from '../../../pages/SharedChat/getConversationBySharedId';
import ConversationItem from '../ConversationItem';
import { useConversations } from '../../../contexts/ConversationsContext';

interface Props {
  setToDelete: (toDelete: boolean) => void;
  setHistory: (history: Message[]) => void;
}

const ConversationList = ({ title, conversations, setToDelete, setHistory }: ConversationList & Props) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<Conversation | null>(null);

  const { conversationId, setConversationId, getConversationById, deleteConversationById } = useConversations();

  const handleConversationItemClick = (conversationId: string) => {
    autofocusCursor();
    getConversationById(conversationId);
    setConversationId(conversationId);
    setConversationId(conversationId);
    generateBaseURL(conversationId);
  };

  const handleDeleteClick = (conversation: Conversation) => {
    setItemToDelete(conversation);
    setDialogOpen(true);
    setToDelete(true);
  };

  const handleDeleteConfirm = async () => {
    autofocusCursor();
    if (itemToDelete !== null) {
      await deleteConversationById(itemToDelete.conversation_id);
      setDialogOpen(false);
      if (conversations.length === 1) {
        setHistory([]);
      } else if (conversationId === itemToDelete.conversation_id) {
        setHistory([]);
      }
      //global.location.reload();
    }
  };

  const generateBaseURL = async (conversationId: string) => {
    const response = await getSharedId(conversationId);
    const sharedId = response.replace(/"/g, '');
    const port = window.location.port;
    const URL = `${window.location.protocol}//${window.location.hostname}${port ? ':' + port : ''}`.concat(
      `/shared?conversation_id=${conversationId}&shared_id=${sharedId}`,
    );
    return URL;
  };

  const handleSharedClick = async (conversationId: string) => {
    if (conversationId) {
      try {
        const sharedUrl = await generateBaseURL(conversationId);
        await navigator.clipboard.writeText(sharedUrl);
        toast.info(
          `Shared link to the conversation is copied to the clipboard. You may be sending confidential information. Your conversation cannot be influenced!`,
        );
      } catch (error) {
        toast.warn('Could not share the conversation text: ' + error);
      }
    }
  };

  const handleDeleteCancel = () => {
    setDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <div className="sidemenu-conversation-link-container">
      <h6 className="sidemenu-conversation-link-title">{title}</h6>
      {conversations.map(conversation => (
        <ConversationItem
          conversation={conversation}
          key={conversation.conversation_id}
          isActive={conversation.conversation_id === conversationId}
          onClick={() => handleConversationItemClick(conversation.conversation_id)}
          onDelete={() => handleDeleteClick(conversation)}
          onShare={() => handleSharedClick(conversation.conversation_id)}
        />
      ))}
      <DeleteDialog
        isOpen={isDialogOpen}
        itemName={itemToDelete?.description ?? ''}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  );
};

export default ConversationList;
