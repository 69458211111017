import bird from './bird.svg';
import cherryBlossom from './cherry-blossom.svg';
import chess from './chess.svg';
import duck from './duck.svg';
import gClef from './g-clef.svg';
import golfBalls from './golf-balls.svg';
import hedgehog from './hedgehog.svg';
import person from './person.svg';
import polyhedron from './polyhedron.svg';
import smiley from './smiley.svg';
import stork from './stork.svg';

const profilePictures = [
  bird,
  cherryBlossom,
  chess,
  duck,
  gClef,
  golfBalls,
  hedgehog,
  person,
  polyhedron,
  smiley,
  stork,
];

const secureRandomNumber = (max: number): number => {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0] % max;
};

const randomIndex = secureRandomNumber(profilePictures.length);
const randomProfilePicture = profilePictures[randomIndex];

export {
  bird,
  cherryBlossom,
  chess,
  duck,
  gClef,
  golfBalls,
  hedgehog,
  person,
  polyhedron,
  profilePictures,
  randomProfilePicture,
  smiley,
  stork,
};
