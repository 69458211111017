import { useAccount, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Buffer } from 'buffer';

type maybeString = string | undefined;

const useUserInfo = () => {
  const [username, setUsername] = useState('');
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [email, setEmail] = useState<maybeString>();
  const [profilePicture, setProfilePicture] = useState<maybeString>();

  useEffect(() => {
    if (account && account.name) {
      if (username !== account.username) {
        setUsername(account.username);
        setEmail(account.username); // Use the MSAL account's username
        (async () => {
          // profile photo is available via Graph API. For accessing we need an access token
          const token = (
            await instance.acquireTokenSilent({
              scopes: ['user.read'],
              account,
            })
          ).accessToken;

          const profilePictureResponse = await fetch('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', {
            headers: {
              'Content-Type': 'image/jpg',
              'Authorization': `Bearer ${token}`,
            },
          });

          if (!profilePictureResponse.ok) {
            throw profilePictureResponse;
          }

          const profilePicture = Buffer.from(await profilePictureResponse.arrayBuffer()).toString('base64');

          setProfilePicture(profilePicture);
        })();
      }
    }
  }, [account, instance, username, setEmail, setProfilePicture]);

  return { email, profilePicture };
};
export default useUserInfo;
