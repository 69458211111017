import { toast } from '../Toast/';

export const TOO_MANY_REQUESTS_STATUS_CODE = 429;
export const GENERIC_APPLICATION_ERROR_ID = 10000;
export const BAD_REQUEST_ERROR_ID = 10001;
export const AZURE_NOT_AVAILABLE_ERROR_ID = 10002;
export const CONVERSATION_NOT_FOUND_ERROR_ID = 10003;
export const MISMATCHING_OWNER_ERROR_ID = 10004;

export const AZURE_AI_ERROR_MESSAGE = 'Azure AI is currently not available. Please come back for a chat later!';
export const GENERIC_ERROR_MESSAGE = 'ChatAI is currently unavailable, we are doing our best to bring it back to life!';
export const REQUEST_ERROR_MESSAGE =
  'We could not execute your last request. Could you please try one more time or reload?';
export const SERVER_ERROR_MESSAGE =
  ' There is some issue with our service. Could you please try one more time or reload?';
export const TOO_MANY_REQUESTS_ERROR_MESSAGE = 'Our servers are under heavy load, please try again in a few seconds!';
export const CONVERSATION_ERROR_MESSAGE = 'Something went wrong with conversation!';

export const showErrorToastOn = async (error: any) => {
  if (error.status === TOO_MANY_REQUESTS_STATUS_CODE) {
    return toast.warn(TOO_MANY_REQUESTS_ERROR_MESSAGE);
  }

  const data = await error.json();
  const detailMessage = data.detailMessage ?? '';

  if (data.errorId === GENERIC_APPLICATION_ERROR_ID) {
    return toast.warnWithDetail(SERVER_ERROR_MESSAGE, detailMessage);
  }

  if (data.errorId === BAD_REQUEST_ERROR_ID) {
    return toast.warnWithDetail(REQUEST_ERROR_MESSAGE, detailMessage);
  }

  if (data.errorId === AZURE_NOT_AVAILABLE_ERROR_ID) {
    return toast.warnWithDetail(AZURE_AI_ERROR_MESSAGE, detailMessage);
  }

  if (data.errorId === CONVERSATION_NOT_FOUND_ERROR_ID) {
    return toast.warnWithDetail(CONVERSATION_ERROR_MESSAGE, detailMessage);
  }

  if (data.errorId === MISMATCHING_OWNER_ERROR_ID) {
    return toast.warnWithDetail(CONVERSATION_ERROR_MESSAGE, detailMessage);
  }

  return toast.warnWithDetail(GENERIC_ERROR_MESSAGE, detailMessage);
};
