interface Props {
  isChecked: boolean;
  handleChange: () => any;
}
const TermsOfServiceCheckbox: React.FC<Props> = ({ isChecked, handleChange }) => (
  <div className="form-check">
    <label style={{ cursor: 'pointer' }} className="form-check-label " htmlFor="ToSConsent">
      <input className="form-check-input" type="checkbox" checked={isChecked} id="ToSConsent" onChange={handleChange} />
      <strong>
        <u>I hereby accept the terms of service to use this app</u>
      </strong>
    </label>
  </div>
);

export default TermsOfServiceCheckbox;
