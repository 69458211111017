import { toast } from '../Toast';
import Button from 'react-bootstrap/Button';
import useCopyButton from './useCopyButton';

const CopyButton = (props: { content: string }) => {
  const handleClick = useCopyButton(props.content);

  return (
    <>
      <Button size={'sm'} onClick={handleClick} title={'Copy message'}>
        COPY
      </Button>
    </>
  );
};

export default CopyButton;
