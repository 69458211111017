import { useContext } from 'react';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorageConnector';
import { termsOfServiceVersion, TOS_LOCAL_STORAGE_KEY } from './termsOfService';
import { ToSContext } from '../../contexts/ToSContext';

const useViewModel = () => {
  const { setShow } = useContext(ToSContext);

  const approveTerms = () => {
    const currentStoredVersion = getLocalStorage(TOS_LOCAL_STORAGE_KEY);

    if (currentStoredVersion !== termsOfServiceVersion) {
      setLocalStorage(TOS_LOCAL_STORAGE_KEY, termsOfServiceVersion);
    }

    setShow(false);
  };

  return { approveTerms, setShow };
};
export default useViewModel;
