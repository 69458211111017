import BaseModal from '../BaseModal/index';
import useViewModel from './viewModel';
import React from 'react';
import { Releases } from './releases';

const ReleaseNotesModal: React.FC = () => {
  const { showReleaseNotes, handleClose, releaseVersion, releaseNotes } = useViewModel();

  return (
    <BaseModal
      footer=""
      show={showReleaseNotes}
      testId="ReleaseNotesModal"
      title="ChatAI Release Information"
      close={handleClose}
    >
      <Releases releaseVersion={releaseVersion} releaseNotes={releaseNotes} />
    </BaseModal>
  );
};
export default ReleaseNotesModal;
