import React, { createContext, useEffect, useMemo, useState } from 'react';
import { transformConversations } from '../../components/SideMenu/transformConversations';
import { useLocation, useNavigate } from 'react-router-dom';
import getConversationByIdApi from '../../components/SideMenu/getConversationByIdApi';
import getConversations from '../../components/SideMenu/getConversationListApi';

interface ConversationsState {
  conversationId: string;
  setConversationId: (conversationId: string) => void;
  conversations: Conversation[];
  conversationList: ConversationList[];
  getConversationById: (conversationId: string) => Promise<void>;
  deleteConversationById: (conversationId: string) => Promise<void>;
  history: Message[];
  setHistory: React.Dispatch<React.SetStateAction<Message[]>>;
}

const ConversationsContext = createContext<ConversationsState | null>(null);

interface Props {}

const ConversationsProvider = ({ children }: React.PropsWithChildren<Props>) => {
  const location = useLocation();
  let state = location.state as { history: Message[]; conversationId: string };
  const [history, setHistory] = useState<Message[]>(state?.history || []);

  const [conversationId, setConversationId] = useState<string>(state?.conversationId || '');
  const [conversations, setConversations] = useState<Conversation[]>([]);

  useEffect(() => {
    getConversations({ setConversations });
  }, [conversationId]);

  const loadConversations = () => {
    getConversations({ setConversations });
  };

  const conversationList = useMemo(() => transformConversations(conversations), [conversations]);

  // TODO: refactor so we can have a pure fetch function and handle state update in the component
  const getConversationById = getConversationByIdApi({
    setConversationId,
    toDelete: false,
    // TODO: remove unused parameters
    setHistory,
    loadConversations,
  });

  // TODO: refactor so we can have a pure fetch function and handle state update in the component
  const deleteConversationById = getConversationByIdApi({
    setConversationId,
    toDelete: true,
    // TODO: remove unused parameters
    setHistory,
    loadConversations,
  });

  const value: ConversationsState = useMemo(
    () =>
      ({
        conversationId,
        setConversationId,
        conversations,
        conversationList,
        getConversationById,
        deleteConversationById,
        history,
        setHistory,
      }) as ConversationsState,
    [conversationId, conversations, conversationList, history],
  );

  return <ConversationsContext.Provider value={value}>{children}</ConversationsContext.Provider>;
};

export const useConversations = () => {
  const context = React.useContext(ConversationsContext);
  if (!context) {
    throw new Error('useConversations must be used within a ConversationsProvider');
  }
  return context;
};

export default ConversationsProvider;
