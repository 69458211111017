import SideMenuComponent from './Component';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  setHistory: (history: Message[]) => void;
  toDelete: boolean;
  setToDelete: (toDelete: boolean) => void;
}

const SideMenu = ({ open, setOpen, setHistory, toDelete, setToDelete }: Props) => {
  return (
    <SideMenuComponent
      open={open}
      setOpen={setOpen}
      setToDelete={setToDelete}
      toDelete={toDelete}
      setHistory={setHistory}
    />
  );
};

export default SideMenu;
