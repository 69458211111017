import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const termsOfServiceVersion = '1.3.0';
const TOS_LOCAL_STORAGE_KEY = 'currently_accepted_tos_version';

const termsOfServiceMarkdown = `
This is a Minimum Viable Product (MVP) with only a few key features implemented. Based on feedback and learnings the application will be improved.

Please read the terms of service and dos and don'ts carefully. You must accept the terms of service to be allowed to use the application.

#### **Do**

* Use Chat AI as a tool for learning and experimenting.
* Be polite and professional in your interactions with Chat AI. Use common sense when interacting.
* Keep your queries concise and specific to receive accurate answers quickly.
* Provide feedback via the feedback form in the footer, so that we can collectively improve this tool.

#### **Don't**

* Do not use Chat AI for personal inquiries or matters unrelated to work.
* Given Microsoft's assurance regarding data privacy and security (detailed below), sharing sensitive information with Chat AI is permissible within the constraints of Microsoft's data handling policies.
* Do not accept outputs from Chat AI unquestioningly, particularly when making decisions. Remember that the results are statistical probabilities of word sequences; they may not be technically correct.
* Utilize Personally Identifiable Information (PII) in interactions with Chat AI only in strict adherence to the European General Data Protection Regulation (GDPR).
* Be aware that any PII output by Chat AI may be inaccurate. Such information must be employed exclusively under GDPR conditions, ensuring due diligence and privacy and data protection compliance.
* Do not violate [Microsoft's Acceptable Use Policy for Online Services.](https://www.microsoft.com/licensing/terms/product/ForOnlineServices/all)
* All company policies and IT governance sources, as well as your compliance trainings, apply when interacting with Chat AI.


#### Data Storage and Deletion
All personal messages communicated with Chat AI are stored for a duration of 180 days. After this period, they are automatically deleted from our systems. Ensure that you have saved or backed up any important information before this time limit, as retrieval after deletion will not be possible.

#### Liability notice
Chat AI is a text generator, not a knowledge base. It will reply with text that can contain misinformation. Always double check the output for errors.

Use Chat AI output at your own risk. You cannot hold anybody liable nor accountable for the consequences of using the Chat AI output except yourself.

The development team cannot influence the Chat AI generated answer and is not responsible for good or bad generated text. 

This service is covered by the standard Terms of Use of Sartorius Digital Workplace - documented here:  [(9) IT Services - Daily (sartorius.com)](https://mydaily.sartorius.com/content/page/61f0196048e7241638a98c46)

Especially chapter 1.2.2:

&nbsp;&nbsp;&nbsp;&nbsp;Use of Your CONTENT by Sartorius: Processing of your CONTENT by Sartorius shall be
done on the basis of Art. 6(1) of the European General Data Protection Regulation
(“GDPR”).

For more information consult following resources:

[FAQ](https://sartorius.service-now.com/sp?id=kb_article_view&sysparm_article=KB0012826) |
[Prompt engineering course](https://wd3.myworkday.com/sartorius/learning/course/82d514e03cce1001de939d192dbd0001?record=f27f9ec9e4ae1001e093a4bdaf540000&type=9882927d138b100019b928e75843018d)

Microsoft's Assurance on Data Handling, as outlined in their official documentation, can be reviewed for detailed information at [https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy](https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy)

Your prompts (inputs) and completions (outputs), your embeddings, and your training data:
- are NOT available to other customers.
- are NOT available to OpenAI.
- are NOT used to improve OpenAI models.
- are NOT used to improve any Microsoft or 3rd party products or services.
- are NOT used for automatically improving Azure OpenAI models for your use in your resource (The models are stateless, unless you explicitly fine-tune models with your training data).
- Your fine-tuned Azure OpenAI models are available exclusively for your use. 

The Azure OpenAI Service is fully controlled by Microsoft; Microsoft hosts the OpenAI models in Microsoft’s Azure environment and the Service does NOT interact with any services operated by OpenAI (e.g. ChatGPT, or the OpenAI API).

Terms of service version: ${termsOfServiceVersion}
`;

const TermsOfService = () => (
  <ReactMarkdown
    components={{
      a: ({ node, ...props }) => (
        //eslint-disable-next-line jsx-a11y/anchor-has-content
        <a target={'_blank'} rel="noreferrer" style={{ textDecorationLine: 'underline' }} {...props} />
      ),
    }}
  >
    {termsOfServiceMarkdown}
  </ReactMarkdown>
);

export { termsOfServiceMarkdown, TermsOfService, termsOfServiceVersion, TOS_LOCAL_STORAGE_KEY };
