import React, { useState } from 'react';
import { getLocalStorage } from '../utils/localStorageConnector';
import { termsOfServiceVersion, TOS_LOCAL_STORAGE_KEY } from '../components/TermsOfServiceModal/termsOfService';

const isConsentValid = (): boolean => getLocalStorage(TOS_LOCAL_STORAGE_KEY) === termsOfServiceVersion;

interface IContext {
  show: boolean;
  setShow: (show: boolean) => void;
}

const defaultValue: IContext = {
  show: !isConsentValid(),
  setShow: () => {},
};
export const ToSContext = React.createContext<IContext>(defaultValue);

export const ToSContextProvider = ({ children }: { children: any }) => {
  const [show, setShow] = useState(!isConsentValid());
  const value = { show, setShow };
  return <ToSContext.Provider value={value}>{children}</ToSContext.Provider>;
};
