import { useState, useRef, useEffect, useCallback, useMemo } from 'react';

function useScrollToBottomButton() {
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chatContainer = scrollContainerRef.current;
    if (chatContainer && isUserAtBottom) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [isUserAtBottom]);

  const handleScroll = useCallback(() => {
    const chatContainer = scrollContainerRef.current;
    if (chatContainer) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainer;
      setIsUserAtBottom(scrollTop + clientHeight >= scrollHeight - 10);
    }
  }, []);

  const scrollToBottom = useCallback(() => {
    const chatContainer = scrollContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
    setIsUserAtBottom(true);
  }, []);

  return useMemo(
    () => ({ scrollToBottom, handleScroll, scrollContainerRef, isUserAtBottom }),
    [scrollToBottom, handleScroll, scrollContainerRef, isUserAtBottom],
  );
}

export default useScrollToBottomButton;
