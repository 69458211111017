import { randomProfilePicture } from './profilePictures';

const UserInfo = ({ email = 'logged in', profilePicture = randomProfilePicture }) => {
  const imageSource = profilePicture === randomProfilePicture ? profilePicture : `data:image;base64, ${profilePicture}`;
  return (
    <div>
      <span className="d-none d-sm-inline">{`${email} `}</span>
      <img
        style={{ borderRadius: '100%', border: '1px solid black' }}
        alt="user"
        src={imageSource}
        height={30}
        width={30}
      />
    </div>
  );
};

export default UserInfo;
