import { toast } from '../components/Toast';
import { GENERIC_ERROR_MESSAGE } from '../components/ChatInputForm/displayError';
import { autofocusCursor } from '../utils/autofocusCursor';
import { useConversations } from '../contexts/ConversationsContext';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { METHOD_POST } from '../utils/constants';
import { getAccessTokenWithoutHook } from '../utils/authentication';
import { isFileMD, isFileYaml } from '../utils/fileTypes/fileUtils';

const supportedFileTypes = [
  'text/plain', // .txt, .text, .log
  'text/html', // .html, .htm
  'application/json', // .json
  'text/markdown', // .md
  'message/rfc822', // .eml, .msg
  'application/rtf', // .rtf
  'application/xml', // .xml
  'text/xml', // .xml
  'text/csv', // .csv
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/epub+zip', // .epub
  'application/vnd.oasis.opendocument.text', // .odt
  'application/pdf', // .pdf
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'text/tab-separated-values', // .tsv
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx, .xls
  'image/jpeg', // .jpg, .jpeg
  'image/png', // .png
  'image/tiff', // .tiff
  'image/bmp', // .bmp
  'application/javascript', // .js
  'text/x-python', // .py
  'text/x-java-source', // .java
  'text/x-c', // .c, .cc, .cpp, .cxx
  'text/x-csharp', // .cs
  'application/x-httpd-php', // .php
  'application/x-ruby', // .rb
  'application/x-swift', // .swift
  'application/typescript', // .ts
  'application/x-yaml', // .yml, .yaml
  'text/x-go', // .go
];

const customFetch = async (url: RequestInfo | URL, formData: FormData) => {
  const scope = process.env.REACT_APP_APP_REGISTRATION_SCOPE ?? '';
  const accessToken = await getAccessTokenWithoutHook([scope]);

  return fetch(url, {
    method: METHOD_POST,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_SUBSCRIPTION ?? '',
    },
    body: formData,
  });
};

interface Props {
  onFileUploaded: (data: any) => void;
}

function useFileUpload({ onFileUploaded }: Props) {
  const { conversationId, setConversationId } = useConversations();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (files: FileList) => {
    if (files && files[0]) {
      const file = files[0];
      if (!supportedFileTypes.includes(file.type) && !isFileMD(file) && !isFileYaml(file)) {
        let parts = file.name.split('.');

        if (file.type && file.type !== '') {
          toast.warn(`Unsupported file type: ${file.type}`);
        } else if (parts.length > 1) {
          let extension = parts[parts.length - 1];
          toast.warn(`Unsupported file type: ${extension}`);
        } else {
          toast.warn(`Unsupported file type: extension is undefined`);
        }
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('conversation_id', conversationId);

      const URL = process.env.REACT_APP_BACKEND_URL + '/upload_file';
      // Start loading
      setLoading(true);

      try {
        const response = await customFetch(URL, formData);

        if (response.ok) {
          // toast.info(`File ${file.name} uploaded`);
          // Reset the file input after the file has been handled
          const data = await response.json();

          if (conversationId === '') {
            setConversationId(data.conversation_id);
          }

          onFileUploaded(data);
        } else {
          if (response.status === 504) {
            navigate('/504-error'); // Redirect to the 504 error page
          }
          const error = await response.json();
          console.error(`${error.Error}`);
          // Display a toast with the error message
          toast.warn(`${error.Error}`);
        }
      } catch (error) {
        console.error('Network error:', error);
        toast.warn(GENERIC_ERROR_MESSAGE);
      } finally {
        // Stop loading regardless of the outcome
        setLoading(false);
      }
    } else {
      console.error('No file selected');
    }
    autofocusCursor();
  };

  return useMemo(() => ({ handleFileUpload, loading }) as const, [handleFileUpload, loading]);
}

export default useFileUpload;
