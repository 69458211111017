import { useEffect, useState } from 'react';

interface Props {
  callOnChecked: () => any;
}

const useViewModel = ({ callOnChecked }: Props) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted && isChecked) {
      callOnChecked();
    }

    return () => {
      mounted = false;
    };
  }, [isChecked, callOnChecked]);

  return { handleChange, isChecked };
};

export default useViewModel;
