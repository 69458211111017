import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Footer from '../Footer';
import TopNavigation from '../Navigation';
import './index.css';

interface Props {
  children: any;
  sideNav?: any;
}

const Layout: React.FC<Props> = ({ children, sideNav }) => {
  return (
    <div className="layout-container">
      <TopNavigation />
      <div className="container-fluid layout-content-container">
        <div className="row h-100">
          <div style={{ display: 'flex', height: '100%' }}>
            <div>{sideNav}</div>
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
