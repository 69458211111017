import ReactMarkdown from 'react-markdown';
import { fetchFactory } from '../../api/apiFactory';
import './index.css';
import CopyButton from '../CopyButton';
import { WeButton } from '@sartorius/web-elements-react-components';
import { METHOD_POST } from '../../utils/constants';
import { toast } from '../Toast';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import CodeBlock from '../CodeBlock';

interface Props {
  role: string;
  content: string;
  index?: number;
  download?: DownloadType;
  conversationId: string;
}

export const profileDisplayName = (role: string): string => {
  if (role === 'user') return 'Me';
  if (role === 'assistant') return 'Chat AI';
  return role;
};

const getFileUrl = () => `${process.env.REACT_APP_BACKEND_URL}/chat/download`;

const errorMessage = () => `Couldn't download file`;

const ChatBubble: React.FC<Props> = ({ role, content, download, conversationId }) => {
  const [downloadName, setDownloadName] = useState(download?.download_name);
  const navigate = useNavigate();

  // Aktualisieren Sie den Zustand, wenn sich die Props ändern
  useEffect(() => {
    if (download?.download_name) {
      setDownloadName(download?.download_name);
    }
  }, [download?.download_name]);
  const handleDownload = async () => {
    const body = JSON.stringify({
      conversation_id: conversationId,
      download_token: download?.download_token,
      download_name: downloadName,
    });
    let response;
    try {
      response = await fetchFactory(getFileUrl())(METHOD_POST)(body);

      if (!response.ok) {
        if (response.status === 504) {
          navigate('/504-error'); // Redirect to the 504 error page
        }

        const errorResponse = await response.json();
        const errors = errorResponse.detail.map((e: { msg: any }) => e.msg).join(', ');
        toast.warn(errors || errorMessage());
        throw new Error(errors || 'Unknown error occurred');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = download?.download_name || 'download.txt';
      document.body.appendChild(a);
      a.click();
      // Clean up
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      toast.warn(error.message || errorMessage());
    }
  };

  //Change showed Name of respective role 'user' and 'assistant'
  if (role === 'system') return <></>;

  const bubbleClass = role === 'system' ? 'systemBubble' : role === 'user' ? 'userBubble' : 'assistantBubble';

  return (
    <div className={`container-fluid mb-2 chatBubble ${bubbleClass}`} data-testid="chat-bubble">
      <div className="row">
        <div className="col mt-1">
          <strong>{profileDisplayName(role)}</strong>
        </div>
        <div className="col mt-1 text-end">
          {role === 'assistant' && !content.startsWith('Successfully uploaded file') && (
            <CopyButton content={content} />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col mt-1">
          <ReactMarkdown remarkPlugins={[remarkGfm]} components={{ code: CodeBlock }}>
            {content}
          </ReactMarkdown>
        </div>
      </div>
      <div className="row">
        <div className="col mb-1 text-end">
          {downloadName && (
            <WeButton mode="ghost" onClick={handleDownload} data-testid="download-button">
              Download file
            </WeButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBubble;
