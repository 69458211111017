import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Navbar style={{ background: '#F5F5F5' }} variant="dark">
      <div className="container-fluid justify-content-center">
        <Navbar.Text style={{ color: '#9D9D9D' }}>
          <a
            href="mailto:ai-apps@sartorius.com?subject=ChatAI Feedback"
            data-testid="footer-form-id"
            style={{ color: 'inherit', textDecoration: 'underline' }}
          >
            Give Feedback
          </a>
          {` | `}
          <a
            href="https://sartorius.service-now.com/sp"
            rel="noreferrer"
            target="_blank"
            data-testid="footer-ticket-id"
            style={{ color: 'inherit', textDecoration: 'underline' }}
          >
            Report issue / bug
          </a>
          {` | `}
          <a
            href="https://sartorius.service-now.com/sp?id=kb_article_view&sysparm_article=KB0012826"
            rel="noreferrer"
            target="_blank"
            data-testid="footer-faq-id"
            style={{ color: 'inherit', textDecoration: 'underline' }}
          >
            FAQ
          </a>
          {` | `}
          <Link
            to={'/terms-of-service'}
            data-testid="footer-tos-id"
            style={{ color: 'inherit', textDecoration: 'underline' }}
          >
            Terms of Service
          </Link>
          {` | `}
          <Link
            to={'/release-info'}
            data-testid="footer-rn-id"
            style={{ color: 'inherit', textDecoration: 'underline' }}
          >
            Release Information
          </Link>
        </Navbar.Text>
      </div>
    </Navbar>
  );
};

export default Footer;
