import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './index.css';

interface Props {
  children: any;
  footer: any;
  show: boolean;
  testId: string;
  title: any;
  close: any;
}

const BaseModalComponent: React.FC<Props> = ({ footer, testId, children, title, show, close }) => {
  const conditionalOnClick = typeof close === 'function' ? { onClick: close } : {};
  return ReactDOM.createPortal(
    <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <div {...conditionalOnClick}>
        <div data-testid={testId} className={`my-modal ${show ? 'show' : ''}`}>
          <div className="my-modal-content">
            <div className="my-modal-header m-3">
              <h3 className="my-modal-title">{title}</h3>
              {close && (
                <button
                  type="button"
                  onClick={close}
                  style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                >
                  X
                </button>
              )}
            </div>
            <div className="m-3">{children}</div>
            <div className="my-modal-footer m-3 mt-4">{footer}</div>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.body,
  );
};
export default BaseModalComponent;
