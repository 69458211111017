import React from 'react';
import { Link } from 'react-router-dom';

// Define the component using TypeScript
const Error504Page: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <h1>504 Gateway Timeout</h1>
      <p>Sorry, we couldn't get a response in time.</p>
      <Link className="btn btn-primary mb-3" to="/">
        {' '}
        Go back to chat
      </Link>
    </div>
  );
};

export default Error504Page;
