import Layout from '../../components/Layout';
import { Link } from 'react-router-dom';
import { Releases as RN } from '../../components/ReleaseNotesModal/releases';
import { useState } from 'react';

const RNotes = () => {
  const [releaseVersion] = useState(localStorage.getItem('releaseVersion') || '');
  const [releaseNotes] = useState(JSON.parse(localStorage.getItem('releaseNotes') || '[]'));

  return (
    <Layout>
      <div
        className="layout-overflow-y-auto h-100 content-padding position-relative"
        style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', maxHeight: '100%', flexGrow: 1 }}
      >
        <div style={{ textAlign: 'left' }}>
          <h2> ChatAI Release Information </h2>
          <RN releaseVersion={releaseVersion} releaseNotes={releaseNotes} />
        </div>
        <Link className="btn btn-primary mb-3" style={{ alignSelf: 'center' }} to="/">
          {' '}
          Go back to chat
        </Link>
      </div>
    </Layout>
  );
};

export default RNotes;
