import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';

import { ToastContainer } from './components/Toast';
import TermsOfServiceModal from './components/TermsOfServiceModal';
import ReleaseNotesModal from './components/ReleaseNotesModal';

import Chat from './pages/Chat';
import TermsOfService from './pages/TermsOfService';
import ReleaseNotesPage from './pages/ReleaseNotes';
import { useAuthentication, AuthenticatedTemplate } from './utils/authentication';
import { useContext } from 'react';
import { ToSContext } from './contexts/ToSContext';
import SharedChatPage from './pages/SharedChat';
import Error504 from './components/Error504/Error504';
import ConversationsProvider from './contexts/ConversationsContext';

const App = () => {
  const { show } = useContext(ToSContext);
  useAuthentication();
  return (
    <AuthenticatedTemplate>
      <div className="App">
        <TermsOfServiceModal />
        {!show ? <ReleaseNotesModal /> : null}
        <ToastContainer />
        <Router>
          <ConversationsProvider>
            <Routes>
              <Route path="/" element={<Chat />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/release-info" element={<ReleaseNotesPage />} />
              <Route path="/shared" element={<SharedChatPage />} />
              <Route path="/504-error" element={<Error504 />} />
            </Routes>
          </ConversationsProvider>
        </Router>
      </div>
    </AuthenticatedTemplate>
  );
};

export default App;
