import './index.css';
import {formatDateTime} from '../../utils/dateTimeFormat';

interface DeleteDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  itemName: string;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ isOpen, onConfirm, onCancel, itemName }) => {
  if (!isOpen) {
    return null;
  }
  
  const [time, description] = itemName.split(': ');

  return (
    <div className="dialog-overlay" data-testid="delete-dialog">
      <div className="dialog">
        <h4>Delete chat?</h4>
        <p>Do you really want to delete this chat (”{formatDateTime(time) + ": " + description}”)? This action cannot get reversed.</p>
        <div className="button-group">
          <button onClick={onCancel} className="button cancel">
            Cancel
          </button>

          <button onClick={onConfirm} className="button delete">
            Delete Chat
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDialog;
