import clsx from 'clsx';
import useDropzone, { DropzoneOptions, DropzoneProps } from '../../hooks/useDropzone';
import styles from './index.module.css';
import { WeTypo } from '@sartorius/web-elements-react-components';
import { ReactComponent as AddFileIcon } from '../../img/add-to.svg';

interface Props extends DropzoneOptions {
  alwaysVisible?: boolean;
}

const Dropzone = (props: Props) => {
  const [dropzoneProps, dropzoneState] = useDropzone(props);

  if (!dropzoneState.isDragging && !props.alwaysVisible) return null;

  return (
    <div
      {...dropzoneProps}
      className={clsx([
        styles.dropzone,
        dropzoneState.isDragging && styles.active,
        dropzoneState.isHovering && styles.hover,
      ])}
    >
      <AddFileIcon className={styles.icon} />
      <WeTypo color="#00A0E6" variant="body-l-medium">
        Drop the file here
      </WeTypo>
    </div>
  );
};

export default Dropzone;
