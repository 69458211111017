import React, { ChangeEventHandler, FormEventHandler, KeyboardEventHandler } from 'react';
import UploadIcon from '../../img/upload.svg';

import 'react-resizable/css/styles.css';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import RingLoader from '../RingLoader';
import './index.css';
import { ResizableBox } from 'react-resizable';
import useDropzone, { DropzoneProps, DropzoneState } from '../../hooks/useDropzone';
import clsx from 'clsx';

interface Props {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  handleKeyDown: KeyboardEventHandler<FormControlElement>;
  formData: string;
  handleInputChange: ChangeEventHandler<FormControlElement>;
  handleFileUpload: ChangeEventHandler<HTMLInputElement>;
  loading: boolean;
  history: Message[];
  stopGeneration: any;
  id: boolean;
}
const ChatInputFormComponent: React.FC<Props> = ({
  handleSubmit,
  handleKeyDown,
  formData,
  handleInputChange,
  handleFileUpload,
  loading,
  stopGeneration,
  id,
}) => {
  return (
    <div>
      {loading && (
        <div className="chat-loader mt-2" data-testid="chat-spinner">
          <RingLoader />
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <div className="d-flex w-100 mb-3 mt-3">
          <div className="d-flex flex-row justify-content-between w-100">
            <Button
              variant="secondary"
              className="chat-button mr-2 tooltip"
              style={{ marginRight: '3px', position: 'relative', marginLeft: '0' }}
            >
              <label style={{ cursor: 'pointer', display: 'block', width: '100%', height: '100%' }}>
                <img src={UploadIcon} alt="Upload" style={{ pointerEvents: 'none' }} />
                <input
                  data-testid="file-input"
                  type="file"
                  onChange={handleFileUpload}
                  style={{
                    opacity: 0,
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    display: 'none',
                  }}
                />
              </label>
              <span className="tooltiptext">Upload file (max size: 10 MB)</span>
            </Button>

            <div style={{ flex: 1 }}>
              <ResizableBox
                width={Infinity} // This will make the width flexible
                height={60} // Initial height
                axis="y" // Allow resizing only on the y-axis
                resizeHandles={['ne']} // Resizer handle in the upper right corner
                minConstraints={[Infinity, 60]} // Minimum size constraints for width and height
                maxConstraints={[Infinity, 410]} // Maximum size constraints for width and height
                handleSize={[8, 8]} // Size of the resizer handle
                className="custom-resizable-box"
              >
                <Form.Control
                  autoFocus
                  className="w-100"
                  name="chatGptInput"
                  type="text"
                  as="textarea"
                  rows={1}
                  id="inputText"
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  value={formData}
                  style={{
                    minHeight: '100%',
                    backgroundColor: '#F5F5F5',
                    borderRadius: 0,
                    borderColor: '#DADADA',
                    width: '100%',
                    resize: 'none',
                    overflow: 'auto',
                  }}
                />
              </ResizableBox>
            </div>
            {loading && id ? (
              /* <WeButton iconOnly mode="primary" onClick={stopGeneration} style={{ marginLeft: '20px' }}>
                Stop
              </WeButton>*/
              <button
                onClick={stopGeneration}
                style={{ marginLeft: '20px' }}
                className="chat-button ml-2 btn btn-primary"
              >
                Stop
              </button>
            ) : (
              <Button type="submit" variant="primary" disabled={!formData || loading} className="chat-button ml-2">
                Send
              </Button>
            )}
          </div>
        </div>
      </Form>
      {/* Token- und Nachrichtenlimit-Hinweise */}
    </div>
  );
};

export default ChatInputFormComponent;
