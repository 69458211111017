import { fetchFactory } from '../../api/apiFactory';
import { METHOD_GET } from '../../utils/constants';
import { toast } from '../Toast/';

interface Props {
  setConversations: (conversations: Conversation[]) => void;
}

const URL = process.env.REACT_APP_BACKEND_URL + `/chat/conversations`;
export const CONVERSATION_LOAD_ERROR_MESSAGE = "Couldn't load past conversations";

const getConversations = async ({ setConversations }: Props) => {
  try {
    const response = await fetchFactory(URL)(METHOD_GET)();

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    setConversations(data.conversations);
  } catch (error: any) {
    toast.warn(CONVERSATION_LOAD_ERROR_MESSAGE);
  }
};

export default getConversations;
