import { fetchFactory } from '../../api/apiFactory';
import { METHOD_GET } from '../../utils/constants';

export const getSharedId = async (conversationId: string): Promise<string> => {
  const buildUrl = (conversationId: string) => `${process.env.REACT_APP_BACKEND_URL}/shared/${conversationId}`;
  let response;
  try {
    response = await fetchFactory(buildUrl(conversationId))(METHOD_GET)();
    return response.text();
  } catch (error: any) {
    throw error;
  }
};

export const getConversationBySharedId = async (conversationId: string, sharedId: string): Promise<any> => {
  const buildUrl = (conversationId: string) => `${process.env.REACT_APP_BACKEND_URL}/shared/${conversationId}`;
  let response;

  try {
    response = await fetchFactory(`${buildUrl(conversationId)}/${sharedId}`)(METHOD_GET)();
    return response.json();
  } catch (error: any) {
    throw error;
  }
};
