import React from 'react';
import useViewModel from './viewModel';
import TermsOfServiceCheckbox from './Component';

interface Props {
  callOnChecked: () => any;
}

const TosCheckBox: React.FC<Props> = ({ callOnChecked }) => {
  const { handleChange, isChecked } = useViewModel({ callOnChecked });
  return <TermsOfServiceCheckbox {...{ handleChange, isChecked }} />;
};

export default TosCheckBox;
