import './index.css';
const RingLoader = () => (
  <div className="lds-ring" data-testid="spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default RingLoader;
