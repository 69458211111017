import './index.css';
import CommentIcon from '../../../img/comment.svg';

const NewChatButton = ({ onClick, children }: { onClick: React.MouseEventHandler<HTMLElement>; children: string }) => {
  return (
    <button className="new-chat-button" onClick={onClick}>
      <img src={CommentIcon} alt="CommentIcon" />
      {children}
    </button>
  );
};

export default NewChatButton;
