/**
    * Workaround for .md files, as the File uploaded through the input element
    * gets assigned a blank 'type' property when it is a markdown file.
**/
export function isFileMD(file: File): boolean {
    const parts = file.name.split('.');
    return parts[parts.length-1] == 'md';
}

/**
    * Workaround for .yml/.yaml files, as the File uploaded through the input element
    * gets assigned a blank 'type' property when it is uploaded on Windows file.
**/
export function isFileYaml(file: File): boolean {
    const parts = file.name.split('.');
    const extensions = ['yml', 'yaml'];
    return extensions.includes(parts[parts.length-1]);
}
