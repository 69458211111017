import MenuIcon from '../../img/menu.svg';
import NewChatButton from './NewChatButton';
import ConversationList from './ConversationList';

import './index.css';
import { autofocusCursor } from '../../utils/autofocusCursor';
import { useConversations } from '../../contexts/ConversationsContext';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  toDelete: boolean;
  setToDelete: (toDelete: boolean) => void;
  setHistory: (history: Message[]) => void;
}

const SideMenuComponent = ({ open, setOpen, setToDelete, toDelete, setHistory }: Props) => {
  const { conversationId, setConversationId, conversationList, getConversationById, deleteConversationById } =
    useConversations();
  return (
    <nav
      style={{
        backgroundColor: '#FFFFFF',
        borderRight: open ? '1px solid #C6C6C6' : '',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'all 0.1s ease',
        width: open ? '18rem' : '3rem',
        zIndex: 1,
        height: '100%',
        maxHeight: '100%',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 'calc(100% - 80px)' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: open ? 'end' : 'center',
            transition: 'all 0.1s ease',
            marginTop: '.5rem',
          }}
        >
          <img
            src={MenuIcon}
            style={{ marginRight: open ? '1rem' : 0, width: '2rem', height: '2rem' }}
            alt="MenuIcon"
            onClick={() => {
              autofocusCursor();
              setOpen(!open);
            }}
          />
        </div>
        <div
          style={{
            height: 'calc(100% - 150px)',
            overflowY: 'auto',
            display: open ? 'block' : 'none',
            marginTop: '1rem',
            flexGrow: 1,
          }}
        >
          {conversationList.length > 0 ? (
            conversationList.map(({ title, conversations }) => {
              return (
                <ConversationList
                  key={title}
                  {...{
                    title,
                    conversations,
                    getConversationById,
                    deleteConversationById,
                    setToDelete,
                    toDelete,
                    setHistory,
                    setConversationId,
                    conversationId,
                  }}
                />
              );
            })
          ) : (
            <div style={{ paddingLeft: '1rem' }}>No conversations in your history yet.</div>
          )}
        </div>
      </div>
      <div style={{ padding: '1rem', display: open ? 'block' : 'none' }}>
        <NewChatButton
          onClick={() => {
            setConversationId('');
            setHistory([]);
          }}
        >
          New Chat
        </NewChatButton>
      </div>
    </nav>
  );
};

export default SideMenuComponent;
