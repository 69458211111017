import { toast } from '../Toast/';
import { METHOD_POST } from '../../utils/constants';
import { sseFactory } from '../../api/apiFactory';
import { showErrorToastOn, GENERIC_ERROR_MESSAGE } from './displayError';
import { autofocusCursor } from '../../utils/autofocusCursor';

const URL = process.env.REACT_APP_BACKEND_URL + `/chat`;
const ROLE = 'user';
const EMPTY_STRING = '';

const DEFAULT_CONFIG = {
  frequency_penalty: 0,
  presence_penalty: 0,
  stop: null,
  temperature: 0,
  top_p: 0.95,
  streaming: true,
};

interface Props {
  conversationId: string;
  formData: string;
  history: Message[];
  setConversationId: (conversationId: string) => void;
  setFormData: (formData: string) => void;
  setHistory: (history: Message[]) => void;
  setLoading: (loading: boolean) => void;
  setId: (id: boolean) => void;
}

const getChat = async ({
  conversationId,
  formData,
  history,
  setConversationId,
  setFormData,
  setHistory,
  setLoading,
  setId,
}: Props) => {
  const nextHistory = [...history, { role: ROLE, content: formData }];

  const updateChat = (newHistory: Message[], newFormData: string, newConversationId: string) => () => {
    setHistory(newHistory);
    setFormData(newFormData);
    setConversationId(newConversationId);
  };

  const updateChatWithoutInputForm = (newHistory: Message[], newConversationId: string) => {
    setHistory(newHistory);
    setConversationId(newConversationId);
  };

  const updateChatHistoryBeforeFetch = updateChat(nextHistory, EMPTY_STRING, conversationId);
  const rollbackChatHistoryOnFail = updateChat(history, formData, conversationId);

  try {
    updateChatHistoryBeforeFetch();

    const body = JSON.stringify({
      conversation_id: conversationId,
      messages: nextHistory,
      ...DEFAULT_CONFIG,
    });
    const mss = await sseFactory(URL)(METHOD_POST)(body);

    mss.addEventListener('message', (event: any) => {
      const data = event.data && JSON.parse(event.data);
      data && updateChatWithoutInputForm(data.messages || [], data.conversation_id);
      setLoading(true);
      setId(true);
      if (data.finalResponse) {
        setLoading(false);
      }
    });
  } catch (error: any) {
    try {
      rollbackChatHistoryOnFail();
      console.error('error', error);
      await showErrorToastOn(error);
      setLoading(false);
    } catch (error: any) {
      console.error('error', error);
      setLoading(false);
      return toast.warn(GENERIC_ERROR_MESSAGE);
    }
    autofocusCursor();
  }
};

export default getChat;
