import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

// @ts-ignore
export const renderH1 = ({ node, children, ...props }) => {
  const index = node.position?.start.line;
  const style = {
    backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff',
    fontSize: '14px',
    fontFamily: 'TTNormsPro-Regular,Arial,Helvetica,sans-serif',
    padding: '1.2rem 2rem',
  };

  return (
    <h1 style={style} {...props}>
      {children}
    </h1>
  );
};
// @ts-ignore
const Releases = ({ releaseVersion, releaseNotes }) => {
  const combinedMarkdown = `## Version ${releaseVersion}\n${releaseNotes.map((note: any) => `${note}\n`).join('')}`;

  return (
    <ReactMarkdown
      components={{
        h1: renderH1,
        h2: ({ node, children, ...props }) => {
          return (
            <h2 style={{ fontSize: '1.75rem' }} {...props}>
              {children}
            </h2>
          );
        },
        a: ({ node, children, ...props }) => {
          // Ensure anchor tags have content
          if (!children) {
            return null;
          }
          return (
            <a target={'_blank'} rel="noreferrer" style={{ textDecorationLine: 'underline' }} {...props}>
              {children}
            </a>
          );
        },
      }}
    >
      {combinedMarkdown}
    </ReactMarkdown>
  );
};

export { Releases };
