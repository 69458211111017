import { useCallback } from 'react';
import { toast } from '../Toast';

export default function useCopyButton(content: string) {
  return useCallback(() => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        toast.info('The text is copied to the clipboard');
      })
      .catch(error => toast.warn('Could not copy text: ' + error));
  }, [content]);
}
