import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeAppInsights } from './utils/applicationInsights';

import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/ttnormspro-regular-woff2-data.woff2';
import './index.css';
import '@sartorius/web-elements-web-components/dist/sartorius-web-elements-web-components/sartorius-web-elements-web-components.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthenticationProvider } from './utils/authentication';
import { ToSContextProvider } from './contexts/ToSContext';
function Main() {
  // To do: Add Chat provider to get latest conversation in the chat
  return (
    <React.StrictMode>
      <AuthenticationProvider>
        <ToSContextProvider>
          <App />
        </ToSContextProvider>
      </AuthenticationProvider>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Main />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initializeAppInsights();
