import React from 'react';
import DeleteIcon from '../../../img/delete.svg';
import ShareIcon from '../../../img/link.svg';
import {formatDateTime} from '../../../utils/dateTimeFormat';
import './index.css';

interface Props {
  conversation: Conversation;
  isActive: boolean;
  onDelete: () => void;
  onShare: () => void;
  onClick: () => void;
}

const ConversationItem = ({ conversation, isActive, onDelete, onShare, onClick }: Props) => {
  const [time, description] = conversation.description.split(': ');

  return (
    <React.Fragment key={conversation.conversation_id}>
      <div
        data-testid={`conversation-${conversation.conversation_id}`}
        className={'sidemenu-conversation-link ' + (isActive ? 'sidemenu-conversation-link-active' : '')}
        onClick={event => {
          event.stopPropagation(); // Prevent the parent's onClick from firing
          onClick();
        }}
      >
        <span style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', wordBreak: 'break-all' }}>
          <span>{description}...</span>
          <span style={{ fontSize: '.6rem', color: '#7F7F7F' }}>{formatDateTime(time)}</span>
        </span>
        <div className="sidemenu-action-container" data-testid="action-container">
          <div className="sidemenu-conversation-link-icon-container">
            <img
              src={DeleteIcon}
              alt="DeleteIcon"
              className="sidemenu-conversation-link-icon"
              onClick={event => {
                event.stopPropagation();
                onDelete();
              }}
            />
          </div>
          <div className="sidemenu-conversation-link-icon-container">
            <img
              id={`shareIcon-${conversation.conversation_id}`}
              src={ShareIcon}
              alt="ShareIcon"
              className="sidemenu-conversation-link-icon"
              onClick={event => {
                event.stopPropagation(); // Prevent the parent's onClick from firing
                onShare();
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConversationItem;
