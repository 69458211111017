import { useCallback, useMemo } from 'react';
import { useConversations } from '../contexts/ConversationsContext';

function useChat() {
  const { setHistory, setConversationId } = useConversations();

  const _update = useCallback((newConversationId?: string) => {
    if (newConversationId !== undefined) setConversationId(newConversationId);
  }, []);

  const replace = useCallback(
    (newHistory: Message[], newConversationId?: string) => {
      setHistory(newHistory);
      _update(newConversationId);
    },
    [_update],
  );

  const append = useCallback(
    (newMessages: Message[], newFormData: string, newConversationId?: string) => {
      setHistory(prev => [...prev, ...newMessages]);
      _update(newConversationId);
    },
    [_update],
  );

  return useMemo(() => ({ replace, append }), [replace, append]);
}

export default useChat;
