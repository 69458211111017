import SyntaxHighlighter from 'react-syntax-highlighter';
import './syntax-highlight.css';
import styles from './index.module.css';
import { WeTypo } from '@sartorius/web-elements-react-components';
import useCopyButton from '../CopyButton/useCopyButton';

interface CodeBlockProps {
  className?: string;
  inline?: boolean;
}

// Mostly based on the example from React Markdown: https://github.com/remarkjs/react-markdown?tab=readme-ov-file#use-custom-components-syntax-highlight
const CodeBlock = (props: React.PropsWithChildren<CodeBlockProps>) => {
  const { children, className, ...rest } = props;
  const code = String(children);
  const match = /language-(\w+)/.exec(className || '');
  const numberOfLines = children?.toString()?.trim()?.split('\n')?.length ?? 0;
  const showLineNumbers = numberOfLines > 1;
  const handleCopy = useCopyButton(code);

  if (props.inline) return <code {...rest}>{props.children}</code>;

  return (
    <div className={styles['code-block']} data-testid="code-block">
      <div className={styles.header}>
        <div className={styles.title}>{match && <WeTypo variant="label-s">{match[1]}</WeTypo>}</div>
        <button className={styles['copy-button']} onClick={handleCopy} data-testid="copy-button">
          <WeTypo variant="label-s">COPY</WeTypo>
        </button>
      </div>
      {match ? (
        <SyntaxHighlighter
          {...rest}
          PreTag="div"
          children={code.replace(/\n$/, '')}
          language={match[1]}
          useInlineStyles={false}
          className={styles.code}
          showLineNumbers={showLineNumbers}
          lineNumberStyle={{ opacity: 0.5, minWidth: '2em' }}
        />
      ) : (
        <code {...rest} className={styles.code}>
          {children}
        </code>
      )}
    </div>
  );
};

export default CodeBlock;
