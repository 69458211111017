import { getAccessTokenWithoutHook } from '../utils/authentication';
import { SSE } from 'sse.js';
import { METHOD_POST } from '../utils/constants';

type hofReturnFn = (prop: string) => Function;
type hofReturnPromise = (prop: string | undefined) => Promise<Response>;

const buildHeaders = async (method: string) => {
  const scope = process.env.REACT_APP_APP_REGISTRATION_SCOPE ?? '';
  const accessToken = await getAccessTokenWithoutHook([scope]);

  let headers: any = {
    'Authorization': `Bearer ${accessToken}`,
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_SUBSCRIPTION ?? '',
  };
  method === METHOD_POST && (headers['Content-Type'] = 'application/json');

  return headers;
};

export const sseFactory =
  (url: string): hofReturnFn =>
  (method: string): hofReturnPromise =>
  async (body: string | undefined): Promise<any> => {
    const headers = await buildHeaders(method);

    return new SSE(url, {
      method,
      headers,
      payload: body,
      debug: false, // set this to true if you want to read all sse events without console.log on each event type
    });
  };

export const fetchFactory =
  (url: string): hofReturnFn =>
  (method: string): hofReturnPromise =>
  async (body: string | undefined): Promise<any> => {
    const headers = await buildHeaders(method);

    try {
      const response = await fetch(url, {
        method,
        headers,
        body,
      });

      // Check if the response is ok (status in the range 200-299)
      if (!response.ok) {
        if (response.status === 504) {
          // Handle 504 Gateway Timeout error
          throw new Error('Server is taking too long to respond. Please try again later.');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response; // Return the response to be handled by the caller
    } catch (error) {
      console.error('Fetch error:', error);
      // Handle the error appropriately in the UI
      throw error; // Re-throw the error to be handled by the caller if needed
    }
  };
