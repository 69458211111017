import { fetchFactory } from '../../api/apiFactory';
import { METHOD_GET, METHOD_DELETE } from '../../utils/constants';
import { toast } from '../Toast/';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

interface Props {
  setConversationId: (conversationId: string) => void;
  setHistory: (history: Message[]) => void;
  toDelete: boolean;
  loadConversations: () => void;
}

const buildUrl = (conversationId: string) =>
  `${process.env.REACT_APP_BACKEND_URL}/chat/conversations/${conversationId}`;

const deleteURL = (conversationId: string) =>
  `${process.env.REACT_APP_BACKEND_URL}/chat/conversations/delete/${conversationId}`;

const buildErrorMessage = (conversationId: string) => `Couldn't load conversation with id: ${conversationId}`;
const deleteErrorMessage = (conversationId: string) => `Couldn't delete conversation with id: ${conversationId}`;

const useGetConversationById = ({ setHistory, setConversationId, loadConversations, toDelete }: Props) => {
  const navigate = useNavigate();

  const getConversationById = useCallback(
    async (conversationId: string) => {
      let response;
      try {
        if (toDelete) {
          response = await fetchFactory(deleteURL(conversationId))(METHOD_DELETE)();
          setConversationId('');
          loadConversations();
        } else {
          response = await fetchFactory(buildUrl(conversationId))(METHOD_GET)();
          const data = await response.json();
          setHistory(data.messages);
          setConversationId(data.conversation_id);
        }

        if (!response.ok) {
          if (response.status === 504) {
            navigate('/504-error'); // Redirect to the 504 error page
          }
          throw response;
        }
      } catch (error: any) {
        if (toDelete) {
          toast.warn(deleteErrorMessage(conversationId));
        } else {
          toast.warn(buildErrorMessage(conversationId));
        }
      }
    },
    [setHistory, setConversationId, loadConversations, navigate, toDelete],
  );

  return getConversationById;
};

export default useGetConversationById;
