import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking as withAppInsightsTracking } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    //@ts-ignore
    extensions: [reactPlugin],
  },
});

const initializeAppInsights = () => {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
};

export { appInsights, initializeAppInsights, withAppInsightsTracking };
