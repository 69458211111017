  export const formatDateTime = (time: String): String => {
      let utcTime = time + " UTC";
      if (Number.isNaN(new Date(utcTime).valueOf())) {
        utcTime = utcTime.replace(/-/g, "/"); 
      }
      const timeStamp = new Date(utcTime);
      const [day, month, year] = timeStamp.toLocaleDateString().split('/')
      const timeString = timeStamp.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      return `${year}-${month}-${day} ${timeString}`;
  }
