import clsx from 'clsx';
import { useConversations } from '../../contexts/ConversationsContext';
import useDropzone from '../../hooks/useDropzone';
import ChatContainer from '../ChatContainer';
import ChatInputForm from '../ChatInputForm';
import { useState, useRef, useEffect, useCallback, DragEvent } from 'react';
import Button from 'react-bootstrap/Button';
import ArrowDown from '../../img/hero-arrow-down.svg';
import Dropzone from '../Dropzone';
import useChat from '../../hooks/useChat';
import useFileUpload from '../../hooks/useFileUpload';
import useScrollToBottomButton from '../../hooks/useScrollToBottomButton';

interface Props {}

const ChatArea = ({}: Props) => {
  const { conversationId, setConversationId, history, setHistory } = useConversations();
  const chat = useChat();
  const { isUserAtBottom, handleScroll, scrollToBottom, scrollContainerRef } = useScrollToBottomButton();

  const handleFileUploaded = useCallback(
    (data: any) => {
      chat.append(data.messages ?? [], data.conversation_id);
    },
    [chat],
  );
  const { handleFileUpload, loading: fileUploadLoading } = useFileUpload({ onFileUploaded: handleFileUploaded });

  const handleDropzoneDrop = useCallback(
    (e: DragEvent) => {
      handleFileUpload(e.dataTransfer.files);
    },
    [handleFileUpload],
  );

  return (
    <div className="layout-overflow-y-auto h-100 position-relative" style={{ flexGrow: 1 }}>
      <div
        className="content-padding h-100"
        style={{ width: '100%', overflowY: 'auto', maxHeight: '100%', flexGrow: 1 }}
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        <div
          className="d-flex flex-column justify-content-between"
          style={{
            minHeight: '100%',
          }}
        >
          <ChatContainer history={history} />
          <div className="chat-form-container">
            <ChatInputForm
              {...{ history, setHistory, setConversationId, conversationId }}
              loading={fileUploadLoading}
            />
          </div>
        </div>
        {!isUserAtBottom && (
          <Button variant="secondary" onClick={scrollToBottom} className="scroll-to-bottom-button">
            <img src={ArrowDown} alt="Scroll to Bottom" />
          </Button>
        )}
      </div>
      <Dropzone onDrop={handleDropzoneDrop} />
    </div>
  );
};

export default ChatArea;
