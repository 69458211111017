import { ToastContainer as LibraryToastContainer, ToastOptions, toast as libraryToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './overrides.css';
import { autofocusCursor } from '../../utils/autofocusCursor';

const generalConfiguration: ToastOptions = {
  position: 'top-center',
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
  onClose: () => autofocusCursor(),
};

const ToastContainer: React.FC = props => (
  <LibraryToastContainer toastClassName={'gpt-border-radius-none'} {...props} />
);

const toast = (text: string) => libraryToast(text, { ...generalConfiguration });

toast.warn = (text: string) => libraryToast.warn(text, { ...generalConfiguration });

toast.warnWithDetail = (warning: string, detail: string) =>
  libraryToast.warn(<MultiLineToastContent warning={warning} detail={detail} />, { ...generalConfiguration });

toast.info = (text: string) => libraryToast.info(text, { ...generalConfiguration });

const MultiLineToastContent = ({ warning, detail }: { warning: string; detail: string }) => (
  <div data-testid="multi-line-test-id">
    <div>{warning}</div>
    <div>{detail}</div>
  </div>
);

export { ToastContainer, toast };
