import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from '../../components/Toast';
import RingLoader from '../../components/RingLoader';
import { getConversationBySharedId } from './getConversationBySharedId';

const SharedChatPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const reloadNumberCounter = useRef(0);

  const queryParams = new URLSearchParams(location.search);
  let sharedId = queryParams.get('shared_id');
  let conversation_id = queryParams.get('conversation_id');
  useEffect(() => {
    if (reloadNumberCounter.current > 0) {
      return;
    }
    reloadNumberCounter.current++;
    const fetchConversationData = async () => {
      if (sharedId && conversation_id) {
        try {
          const conversationData = await getConversationBySharedId(conversation_id, sharedId);
          const history = conversationData.messages;
          const conversationId = conversationData.conversation_id;
          if (history && conversationId) {
            navigate('/', {
              state: { history, conversationId },
            });
            toast.info('Successfully uploaded shared conversation');
          }
        } catch (error) {
          console.error('Error fetching or sharing conversation:', error);
          navigate('/');
          toast.warn('Could not load the conversation via shared link');
        }
      }
    };

    fetchConversationData();
  }, [location.search, navigate, sharedId]);

  return (
    <div className="chat-loader mt-2">
      <RingLoader />
    </div>
  );
};

export default SharedChatPage;
