import React from 'react';
import { useConversations } from '../../contexts/ConversationsContext';

import InputForm from './Component';
import useViewModel from './viewModel';

interface Props {
  loading: boolean;
}

const ChatInputForm: React.FC<Props> = ({ loading }) => {
  const viewModel = useViewModel();
  return <InputForm {...viewModel} loading={loading || viewModel.loading} />;
};

export default ChatInputForm;
