import { useState } from 'react';
import Layout from '../../components/Layout';
import SideMenu from '../../components/SideMenu';
import './index.css';
import { useConversations } from '../../contexts/ConversationsContext';
import ChatArea from '../../components/ChatArea';

const ChatGptWindow = () => {
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(true);
  const [toDelete, setToDelete] = useState(false);
  const { setHistory } = useConversations();

  return (
    <Layout
      sideNav={
        <SideMenu
          {...{
            open: sideMenuOpen,
            setOpen: setSideMenuOpen,
            setHistory,
            toDelete,
            setToDelete,
          }}
        />
      }
    >
      <ChatArea />
    </Layout>
  );
};

export default ChatGptWindow;
