import Layout from '../../components/Layout';
import { TermsOfService as ToS } from '../../components/TermsOfServiceModal/termsOfService';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <Layout>
      <div
        className="layout-overflow-y-auto h-100 content-padding position-relative"
        style={{ overflowY: 'auto', maxHeight: '100%', flexGrow: 1 }}
      >
        <div style={{ textAlign: 'left' }}>
          <h2> Chat AI Terms of Service </h2>
          <ToS />
        </div>
        <Link className="btn btn-primary mb-3" to="/">
          {' '}
          Go back to chat
        </Link>
      </div>
    </Layout>
  );
};

export default TermsOfService;
